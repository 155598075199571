.spinner {
    position: relative;
    display: inline-flex;
    width: 15px;
    height: 13px;
}
.spinner:before {
    width: 13px !important;
    height: 13px !important;
    top: 0 !important;
    margin-top: 0;
}
.CreateAnEvent .AccountNevent.MuiButton-root.Mui-disabled {
    color: #fff;
}